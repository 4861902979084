import React ,{createRef }from "react";
import BigTitle from "../../Components/BigTitle";
import { withRouter } from "react-router-dom";
import * as dataJSON from "../../Data/BrochureRequest.json";
import "./BrochureRequest.scss";
import Meta from "../../Components/Meta";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import Recaptcha from "react-recaptcha";
class BrochureRequest extends React.Component {
    state = {
        lang: this.props.match.params.lang,
        messageReponse: false,
        messageError: false,
      
        meta: {
            title: dataJSON.default[this.props.match.params.lang].meta.title,
            description: dataJSON.default[this.props.match.params.lang].meta.description,
            canonical: dataJSON.default[this.props.match.params.lang].meta.canonical,
            image: dataJSON.default[this.props.match.params.lang].meta.image,
            keywords: dataJSON.default[this.props.match.params.lang].meta.keywords
        },
        bigtitle: {},
        label: {},
        validate: {},
        country:[],
        regionFr:[],
        countryFR:[],
        countryEn:[],
        commune:[]

       
    };
    _reCaptchaRef = createRef();
    componentDidMount() {
        const script = document.createElement("script");
        script.src =
        "https://www.google.com/recaptcha/api.js?render=6LcRCgYhAAAAAE959V_cpYVPav7dscBaucxP8jc_";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        this.setState({
            lang: this.props.match.params.lang,
            data: dataJSON.default[this.state.lang],
            meta: dataJSON.default[this.state.lang].meta,
            bigtitle: dataJSON.default[this.state.lang].bigtitle,
            label: dataJSON.default[this.state.lang].label,
            validate: dataJSON.default[this.state.lang].validate,
            commune:dataJSON.default[this.state.lang].commune
            
        });


        fetch('https://restcountries.com/v3.1/all')
    .then(response => response.json()
   )
    .then(data => 
      {
      this.setState({ country:data})
     
      // console.log(this.state.countryFR)
    
    });

      fetch('https://geo.api.gouv.fr/regions')
      .then(response => response.json()
     )
      .then(dataR => 
        {
        this.setState({ regionFr:dataR})});
  

        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lang !== this.props.match.params.lang) {
            this.setState({
                lang: this.props.match.params.lang,
                data: dataJSON.default[this.state.lang],
                meta: dataJSON.default[this.state.lang].meta,
                bigtitle: dataJSON.default[this.state.lang].bigtitle,
                label: dataJSON.default[this.state.lang].label,
                validate: dataJSON.default[this.state.lang].validate,
                commune:dataJSON.default[this.state.lang].commune
               
            });
        }
    }

    handleSubmit = (message) => {
        fetch("https://mad-up.com/api/mad-up/items/brochure_request", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstname:message.firstname,
            lastname:message.lastname,
            email:message.email,
            country: message.country,
            region: message.region,
            phone_number:message.phone_number
        })
            ,
        })
        .then(response => {
          console.log(response)
          if (response.status==200){
            this.setState({messageReponse:true});
            this.setState({messageError: false});
             console.log("state:", this.state.messageReponse)   
        } else{
          this.setState({messageError: true});

          this.setState({messageReponse:false});
          console.log("stateError:", this.state.messageError);
        }
      })
        .catch(error => {
          this.setState({messageError: true})
           console.log("stateError:", this.state.messageError);
          console.log('Error: ', error)
        });
        
          
      };

    render() {

        
      const messageMail=this.state.messageReponse;
      const messageError=this.state.messageError;
      
     if (messageMail){
     
       document.querySelector(".message").innerHTML=this.state.data.validate.reponse
       document.querySelector(".message").classList.add("reponseTrue")
     }

     if (messageError){
     
      document.querySelector(".message").innerHTML=this.state.data.validate.error
      document.querySelector(".message").classList.add("reponseError")
    }
  
    const InputEmail = Yup.object().shape({
      email: Yup.string().email(this.state.validate.errorMail).required(this.state.validate.errorForm),
      phone_number: Yup.string().required(this.state.validate.errorForm),
      firstname: Yup.string().required(this.state.validate.errorForm),
      lastname: Yup.string().required(this.state.validate.errorForm),
      recaptcha: Yup.string().required(this.state.validate.errorForm),
      //region: Yup.string().required(this.state.validate.errorForm),
      country: Yup.string().required(this.state.validate.errorForm),
      acceptTerms: Yup.bool()
      .oneOf([true], this.state.validate.checkbox)
  });
  this.state.country.sort(function compare(a, b) {
    if (a.translations.fra.common < b.translations.fra.common)
       return -1;
    if (a.translations.fra.common > b.translations.fra.common )
       return 1;
    return 0;
  });
  
  this.state.country.sort(function compare(a, b) {
    if (a.name.common < b.name.common)
       return -1;
    if (a.name.common > b.name.common )
       return 1;
    return 0;
  });
  this.state.regionFr.sort(function compare(a, b) {
    if (a.nom < b.nom)
       return -1;
    if (a.nom > b.nom )
       return 1;
    return 0;
  });
        return (
            
            <>
             <Meta meta={this.state.meta}/>
             <BigTitle
                        text=""
                        title={this.state.bigtitle.title}
                        span={this.state.bigtitle.span}
                        direct="left"
              />
            
            <div className="form ">
                 
            
            <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            recaptcha: "",
            country: "",
            region: "",
            phone_number:"",
            acceptTerms: false,
          }}
          onSubmit={(values) =>{
            this.handleSubmit(values)
           // console.log(values.recaptcha)
         }}
          validationSchema={InputEmail}
          render={({ values, errors, touched, handleSubmit, setFieldValue, handleBlur,
            handleChange,  isSubmitting}) => (
            <form className="checkboxMail" onSubmit={handleSubmit}>

                <div className="formLabel">
                            <div className="form-group mt-4">
                                <label htmlFor="name">
                                   {this.state.label.firstName} 
                                </label>
                                <Field
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    className="form-control input"
                                   
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            
                            <div className="form-group mt-4">
                                <label htmlFor="lastname">
                                   {this.state.label.lastName}
                                </label>
                                <Field
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    className="form-control input"
                                   
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="phone_number">
                                   {this.state.label.phone_number} 
                                </label>
                                <Field
                                    type="text"
                                    id="phone_number"
                                    name="phone_number"
                                    className="form-control input"                                   
                                />
                                <ErrorMessage
                                    name="phone_number"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>
                            <div className="form-group  mb-3">
                                <label htmlFor="email">
                                    {this.state.label.email}
                                </label>
                               
                                <Field
                                    type="email"
    
                                    id="email"
                                    name="email"
                                    className="form-control input"
                                   
                                />
                                <ErrorMessage
                                    name="email"
                                    component="small"
                                    className="text-danger"
                                />
                            </div>

                            <div className="form-group mb-3">
                      <label htmlFor="country">{this.state.label.country}</label>
                      <Field as="select" name="country" className="form-control input">
                      <option disabled  value="">{this.state.label.placeholderCountry}</option>
                      {this.state.country.map((country,index) => {
                       return (this.state.lang ==="fr") ?
                          <option key={index} value={country.translations.fra.common}>
                          {country.translations.fra.common}
                        </option>
                        :
                        <option key={country.name.common} value={country.name.common}>
                          {country.name.common}
                        </option>
                  })}
                    </Field>
                      <ErrorMessage
                        name="country"
                        component="small"
                        className="text-danger"
                      />
                     
                   
                  </div>
                  {values.country==="France" && 
                     <div className="form-group mb-3">
                     <label htmlFor="region">{this.state.label.region}</label>
                       
                       <Field as="select" name="region" className="form-control input" >
                       <option disabled  value="">{this.state.label.placeholderRegion}</option>
                       {this.state.regionFr.map(({nom,index}) => (
                       <option key={nom} value={nom}>
                         {nom} 
                       </option>
                       ))}
                     </Field>
                       <ErrorMessage
                         name="region"
                         component="small"
                         className="text-danger"
                       />
                   </div>
                  }

                    {((values.country==="Spain")|| (values.country==="Espagne")) && 
                     <div className="form-group mb-3">
                     <label htmlFor="region">{this.state.label.region}</label>
                       
                       <Field as="select" name="region" className="form-control input"  >
                       <option disabled  value="">{this.state.label.placeholderRegion}</option>
                       {this.state.commune.sort().map((nom) => (
                       <option key={nom} value={nom}>
                         {nom}
                       </option>
                       ))}
                     </Field>
                       <ErrorMessage
                         name="region"
                         component="small"
                         className="text-danger"
                       />
                       
                      
                 
                   </div> 
                   }
              <div className="form-group">
               
                 <Recaptcha
                  sitekey="6LcRCgYhAAAAAFZQ0kUrOCx-HgCMVxhlWNZ2gZMr"
                  render="explicit"
                  
                  verifyCallback={(response) => {
                    console.log(response)
                    setFieldValue("recaptcha", response); }}
                  onloadCallback={() => { console.log("done loading!"); }}
                />
                {errors.recaptcha 
                  && touched.recaptcha && (
                  <p className="text-danger small">{errors.recaptcha}</p>
                )} 
             
              </div>
              <div className="form-group">
                                    <Field
                                        name="acceptTerms"
                                        type="checkbox"
                                        // className="form-check-input"
                                    />
                                    <label
                                        htmlFor="acceptTerms"
                                        //className="form-check-label"
                                    >
                                       {this.state.label.checkbox}
                                    </label>
                                    <ErrorMessage
                                        name="acceptTerms"
                                        component="small"
                                        className="text-danger"
                                    />
                                    
                 </div>
              <button type="submit" 
                className="colorButton"
                disabled={isSubmitting}
                            
            >{this.state.validate.textSubmit}</button>
            </form>
          )} />
             <p className="message"></p>
             </div>
             </>    
           
        );
    }
}

export default withRouter(BrochureRequest);
